import http from "@/http/http.interceptors";
import { Message } from "element-ui";

/**
 *
 * @param {*} url 请求地址
 *
 *
 */
export default function exportFile({ url, data, type, prevent }) {
    return new Promise((resolve) => {
        let params = {
            method: type || "get",
            url: url,
            responseType: "blob",
        }
        if(!type || type == 'get'){
            params.params = data
        } else if(type == 'post'){
            params.data = data
        }
        http(params)
            .then((res) => {
                let resData = res.data
                if (resData.type === 'application/json') {
                    // 说明是普通对象数据，读取信息
                    let fileReader = new FileReader()
                    fileReader.onloadend = () => {
                        let jsonData = JSON.parse(fileReader.result)
                        // 后台信息
                        console.log(jsonData)
                        Message.error(jsonData.msg);
                        resolve(false);
                    }
                    fileReader.readAsText(res.data)  //或者读取response.data
                } else {
                    let fileName, blob;
                    blob = new Blob([res.data]);
                    let url = window.URL.createObjectURL(blob);
                    try {
                        let nameStr = res.headers["content-disposition"].split(";")
                        nameStr.forEach(item => {
                            if (item.indexOf('filename') != -1) {
                                fileName = decodeURI(
                                    item.split("=")[1]
                                );
                                if(fileName.indexOf('+')>=0){
                                    fileName=fileName.replace(/\+/g," ");
                                }
                            }
                        })
                        fileName ? null : fileName = "Download"
                    } catch (err) {
                        fileName = "Download";
                    }
                    // console.log(fileName)
                    fileName = fileName.trim()
                    if (prevent) {
                        resolve({
                            url, fileName
                        });
                        return
                    }
                    //兼容IE浏览器、以及IE内核
                    if (window.navigator.msSaveOrOpenBlob) {
                        navigator.msSaveBlob(blob, fileName);
                    } else {
                        const link = document.createElement("a");
                        link.href = url
                        link.style.display = "none";
                        link.setAttribute("download", fileName);
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        window.URL.revokeObjectURL(link);
                    }
                    resolve({
                        url, fileName
                    });
                }
            })
            .catch(() => {
                resolve(false);
            });
    });
}
